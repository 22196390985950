const baseURL='https://www.lifotechnologies.com/dynamic1/api.php';

export async function postContactUs(first_name,last_name,company_name,email,interested_in,hearAboutUs,message,process)
{
    try
    {
        const response = await fetch(baseURL,{
            method:'POST',
            body: JSON.stringify({first_name:first_name,last_name:last_name,company_name:company_name,email:email,interested_in:interested_in,hearAboutUs:hearAboutUs,message:message,process:process
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}