import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import FontAwesome from 'react-fontawesome';
import FsLightbox from "fslightbox-react";
// Add Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Facts from "../../Components/Facts";

const LpgVendingMachine = () => {
  useState(() => {
    window.scrollTo(0, 0)
  }, []);
  
  const [toggler, setToggler] = useState(false);

  return (
    <div>
        <Header />
          <section className="section techpattern inner-banner">
            <Container>
              <Row className="text-light text-center justify-content-center">             
                  <Col lg={9} md={9}>
                    <h1>India's first LPG Cylinder Vending Machine</h1>
                    <ul className="breadcrumb_cus">
                      <li><Link to="/" className="link-icon">Home</Link></li>
                      <li>Products</li>
                      <li>India's first LPG Cylinder Vending Machine</li>
                    </ul>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          {/* <section className="section">
            <Container>
              <Row className="text-light">             
                  <Col lg={5} className="">
                  <div className="">
                    <img src="assets/images/products/lpg-vending-machine/lpg-vending-machine-1.png" className="img-fluid" alt="lpg-vending-machine" />
                  </div>                    
                  </Col>
                  <Col lg={{ span: 6, offset: 1 }}>
                    <h2 className="mb-4">Revolutionize Your Gas Distribution with Our State-of-the-Art LPG Cylinder Vending Machine in India.</h2>
                    <h5 className="mb-4">Seamless and Secure LPG Distribution - Engineered for Safety, Efficiency, and Unmatched Compliance.</h5>
                    <p><strong>Innovative, secure, and reliable</strong> our LPG Vending Machine in India is engineered to transform how you distribute gas. From advanced AI and IoT integration to rigorous safety standards, this machine is your gateway to the future of gas distribution.</p>
                    <p>Discover the future of LPG distribution with our innovative LPG Vending Machine in India. Seamlessly integrating advanced technologies like AI and IoT, this machine is designed for ultimate safety, efficiency, and reliability. Equipped with high-end sensors and certified to meet rigorous industry standards, our solution ensures secure and intelligent cylinder dispensing for any environment.</p>
                    <Button href="/contact-us" variant="primary" className="mt-4" >Get a Quote</Button>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section service bg3 bg-theme">
            <Container>
              <Row className="text-light text-center justify-content-center">
                <Col lg={9} md={10}>                                                               
                    <h2 className="mb-4"><strong>Key Features of</strong><br/>LPG Cylinder Vending Machine in India</h2>
                    <p className="mb-5 sub-title">Discover the future of LPG distribution with our innovative LPG Vending Machine in India. Seamlessly integrating advanced technologies like AI and IoT, this machine is designed for ultimate safety, efficiency, and reliability. Equipped with high-end sensors and certified to meet rigorous industry standards, our solution ensures secure and intelligent cylinder dispensing for any environment.</p>
                </Col>
              </Row>
              <Row className="text-light text-center justify-content-center">
                <Col lg={3} md={4} xs={6}>                                                               
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down">
                        <img src="assets/images/icons/vending/gas.svg" alt="automated-dispensing" />
                      </div>
                      <h3 className="mt-4 mb-2">Automated<br/>Dispensing</h3>
                      <p className="sub-title">Ensure consistent, error-free service with our fully automated system.</p>
                    </div>
                </Col>
                <Col lg={3} md={4} xs={6}>
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down"><img src="assets/images/icons/vending/certificate.svg" alt="certificate" /></div>
                      <h3 className="mt-4 mb-2">Compliance with<br/>Global Standards</h3>
                      <p className="sub-title">Meets IEC, PESO, CIMFR, BIS, and ISO certifications.</p>                
                    </div>
                </Col>
                <Col lg={3} md={4} xs={6}>
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down"><img src="assets/images/icons/vending/ui.svg" alt="User-Friendly Interface" /></div>
                      <h3 className="mt-4 mb-2">User-Friendly<br/>Interface</h3>
                      <p className="sub-title">Simple and intuitive controls make operation easy for everyone.</p>
                    </div> 
                </Col>
                <Col lg={3} md={4} xs={6}>
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down"><img src="assets/images/icons/vending/safe.svg" alt="Durable Construction" /></div>
                      <h3 className="mt-4 mb-2">Durable<br/>Construction</h3>
                      <p className="sub-title">Built to last with IP66/IP67 standards, protecting against dust and water.</p>
                    </div> 
                </Col>
                <Col lg={3} md={4} xs={6}>                                                               
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down">
                        <img src="assets/images/icons/vending/design.svg" alt="Futuristic Design" />
                      </div>
                      <h3 className="mt-4 mb-2">Futuristic<br/>Design</h3>
                      <p className="sub-title">Equipped with advanced sensors including Gas Leak Detection, Smoke Detection, Motion, and Vibration Sensors, ensuring robust and secure operation.</p>
                    </div>
                </Col>
                <Col lg={3} md={4} xs={6}>
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down">
                        <img src="assets/images/icons/vending/ai.svg" alt="AI-Powered Precision" />
                        </div>
                      <h3 className="mt-4 mb-2">AI-Powered<br/>Precision</h3>
                      <p className="sub-title">Utilizes artificial intelligence to accurately detect and dispense the correct LPG cylinders, ensuring flawless and efficient service.</p>                
                    </div>
                </Col>
                <Col lg={3} md={4} xs={6}>
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down"><img src="assets/images/icons/vending/payment.svg" alt="Digital Purchase" /></div>
                      <h3 className="mt-4 mb-2">Digital<br/>Purchase</h3>
                      <p className="sub-title">Pay via UPI, Credit/Debit Cards and have a hassle-free access to LPG.</p>
                    </div> 
                </Col>
                <Col lg={3} md={4} xs={6}>
                    <div className="swidget">
                      <div class="sicon" data-aos="fade-down"><img src="assets/images/icons/vending/install.svg" alt="Easy Integration" /></div>
                      <h3 className="mt-4 mb-2">Easy<br/>Integration</h3>
                      <p className="sub-title">Quick integration with any existing systems.</p>
                    </div> 
                </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
        </section>

          <section className="section">
            <Container>
              <Row className="text-light">             
                  <Col lg={5} className="">
                    <h2 className="mb-4">Versatile Applications</h2>
                    <ul className="list2 mt-5">
                      <li>
                        <h5>Residential Complexes</h5>
                        <p>Convenient and safe access to LPG for home cooking.</p>
                      </li>
                      <li>
                        <h5>Retail Outlets</h5>
                        <p>Enhance customer service with quick and easy LPG cylinder purchases.</p>
                      </li>
                      <li>
                        <h5>Industrial Facilities</h5>
                        <p>Reliable and efficient LPG supply for large-scale operations.</p>
                      </li>
                      <li>
                        <h5>Remote Locations</h5>
                        <p>Ensure consistent LPG availability, even in hard-to-reach areas.</p>
                      </li>
                    </ul>
                    <h6 className="mt-5">Designed to meet the needs of various environments, our LPG Vending Machine adapts to any setting with ease. </h6>
                  </Col>
                  <Col lg={{ span: 5, offset: 1 }}>
                    <div className="video-player">
                      <a href="javascript:void(0);" onClick={() => setToggler(!toggler)} title="Watch Video">
                        <img src="assets/images/products/lpg-vending-machine/video-screen.png" alt="lpg-vending-machine" className="img-fluid" />
                        <div className="play-icon"><img src="assets/images/icons/vending/play-button.svg" alt="" className="img-fluid"/></div>
                      </a>
                      <FsLightbox toggler={toggler} sources={[
                          'https://youtu.be/FyxxxWwg8B0',                        
                        ]}
                      />
                    </div>
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <section className="section">
            <Container>
              <Row className="text-light text-center justify-content-center mb-5">
                <Col lg={9} md={10}>                                                               
                    <h2 className="mb-3">Benefits for LPG Cylinder Vending Machine for you!</h2>
                </Col>
              </Row>
              <Row className="text-light">             
                <Col lg={6} md={6} className="mb-4">
                  <div className="sicon mb-2"><img src="assets/images/icons/vending/efficiency.svg" alt="efficiency" /></div>
                  <h4 className="mb-2">Enhanced Distribution Efficiency</h4>
                  <p>Streamline the supply chain with automated dispensing, reducing manual labor and operational costs.</p>          
                </Col>
                <Col lg={6} md={6} className="mb-4">
                  <div className="sicon mb-2"><img src="assets/images/icons/vending/safety.svg" alt="safety" /></div>
                  <h4 className="mb-2">Increased Safety Standards</h4>
                  <p>Advanced sensors and AI technology ensure the highest safety protocols, minimizing the risk of accidents and ensuring compliance with industry regulations.</p>          
                </Col>
                <Col lg={6} md={6} className="mb-4">
                  <div className="sicon mb-2"><img src="assets/images/icons/vending/resource.svg" alt="resource" /></div>
                  <h4 className="mb-2">Optimized Resource Allocation</h4>
                  <p>Automate cylinder detection and dispensing, allowing for better management of LPG resources and reducing wastage.</p>          
                </Col>
                <Col lg={6} md={6} className="mb-4">
                  <div className="sicon mb-2"><img src="assets/images/icons/vending/access.svg" alt="access" /></div>
                  <h4 className="mb-2">Improved Customer Access</h4>
                  <p>Facilitate easy, 24/7 access to LPG cylinders for consumers across various locations, including remote and underserved areas.</p>          
                </Col>
                <Col lg={6} md={6} className="mb-4">
                  <div className="sicon mb-2"><img src="assets/images/icons/vending/data.svg" alt="data" /></div>
                  <h4 className="mb-2">Data-Driven Insights</h4>
                  <p>Leverage IoT connectivity to collect and analyze usage data, enabling informed decision-making and predictive maintenance.</p>          
                </Col>
                <Col lg={6} md={6} className="mb-4">
                  <div className="sicon mb-2"><img src="assets/images/icons/vending/sustainability.svg" alt="sustainability" /></div>
                  <h4 className="mb-2">Sustainability</h4>
                  <p>Reduce carbon footprint by minimizing transportation needs and ensuring efficient use of resources through automated systems.</p>          
                </Col>
                <Col lg={6} md={6} className="mb-4">
                  <div className="sicon mb-2"><img src="assets/images/icons/vending/brand.svg" alt="brand" /></div>
                  <h4 className="mb-2">Brand Reputation</h4>
                  <p>Position ONGC as a leader in adopting innovative and customer-centric solutions, enhancing brand image and customer trust.</p>          
                </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section> */}

          <section className="section">
            <Container>
              <Row className="text-light justify-content-center">             
                  <Col lg={6} className="text-center">
                    <h2>Launching Soon</h2>
                    <img src="assets/images/products/lpg-vending-machine/video-screen.png" alt="lpg-vending-machine" className="img-fluid" />                    
                  </Col>
              </Row>
            </Container>
            <div className="line bottom"></div>
          </section>

          <Facts />
          <Footer />
    </div>
  );
};
export default LpgVendingMachine;